import httpClient from '../httpClient/httpClient';

const SqlStatementClinicResultsService = {
  getSqlStatementClinicResults(page, size) {
    return httpClient.get('api/get-sql-statement-clinic-results', {
      params: {
        page,
        size,
      },
    });
  }
};

export default SqlStatementClinicResultsService;
