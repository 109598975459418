var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.hasPermission)?_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('mex-heading',{attrs:{"content":"SQL Statement - Results"}})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('mex-sheet',{attrs:{"color":"foreground","rounded":""}},[_c('mex-data-table',{attrs:{"data":_vm.sqlStatementClinicResultsData,"enableServerSidePagination":true,"headers":_vm.sqlStatementClinicResultsHeaders,"hide-default-footer":true,"isLoading":_vm.sqlStatementClinicResultsLoading,"srvSideActPage":_vm.actPage,"srvSideActSize":_vm.actSize,"srvSideTotalItems":_vm.totalItems,"srvSideTotalPages":_vm.totalPages,"srvSideSizeItems":_vm.getTablePagination.rowsPerPageWithNames,"item-key":"SqlStatementClinicResultID","loadingText":"Loading SQL statement result...","tableClass":"foreground"},on:{"pageChanged":_vm.handlePageChange,"sizeChanged":_vm.handleSizeChange},scopedSlots:_vm._u([(_vm.readClinics)?{key:"item.goToClinic",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"foreground","small":""},on:{"click":function($event){return _vm.goToClinicView(item.SqlStatementsClinic.ClinicID)}}},[_vm._v(_vm._s(item.SqlStatementsClinic.Clinic.name)+" ")])]}}:{key:"item.goToClinic",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.SqlStatementsClinic.Clinic.name)+" ")]}},{key:"item.goToStatement",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"foreground","small":""},on:{"click":function($event){return _vm.goToStatementEditor(item.SqlStatementsClinic.SqlStatementID)}}},[_vm._v(_vm._s(item.SqlStatementsClinic.SqlStatement.name)+" ")])]}},{key:"item.SqlStatementsClinic.linkedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.SqlStatementsClinic.linkedAt.replace('Z', '').replace('T', ' '))+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.createdAt.replace('Z', '').replace('T', ' '))+" ")]}},{key:"item.result",fn:function(ref){
var item = ref.item;
return [_c('mex-btn',{attrs:{"icon":"mdi-table-eye","iconOnly":""},on:{"click":function($event){return _vm.fillResultTable(item)}}})]}},{key:"item.resultLength",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getResultLength(item))+" ")]}}],null,true)})],1)],1)],1),_c('mex-dialog',{attrs:{"dialogTitle":((_vm.resultTable.statementName) + " of " + (_vm.resultTable.clinicName)),"scrollbarLess":false,"showDialog":_vm.showResultTable,"width":"100%"},scopedSlots:_vm._u([{key:"dialog-content",fn:function(){return [_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('mex-data-table',{attrs:{"data":_vm.resultTable.data,"headers":_vm.resultTable.headers,"footer-props":{
              'items-per-page-options': _vm.getTablePagination.rowsPerPage,
            },"items-per-page":_vm.getTablePagination.defaultRowsPerPage}})],1)],1)]},proxy:true}],null,false,625691135)},[_c('template',{slot:"dialog-actions"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('mex-btn',{attrs:{"content":"Close","icon":"mdi-close"},on:{"click":_vm.clearResultTable}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('mex-btn',{attrs:{"content":".json","icon":"mdi-code-json","width":"100%"},on:{"click":function($event){return _vm.downloadSqlStatementResults('json')}}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('mex-btn',{attrs:{"content":".csv","icon":"mdi-file-excel","width":"100%"},on:{"click":function($event){return _vm.downloadSqlStatementResults( 'csv')}}})],1)],1),(_vm.isDownloading)?_c('v-overlay',[_c('mex-sperm-spinner',{attrs:{"spinnerText":_vm.isDownloadingText}})],1):_vm._e()],1)],2)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }