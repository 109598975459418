<template>
  <v-container v-if="hasPermission" fluid>
    <v-row justify="center">
      <v-col cols="12">
        <mex-heading content="SQL Statement - Results" />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <mex-sheet color="foreground" rounded>
          <mex-data-table
            :data="sqlStatementClinicResultsData"
            :enableServerSidePagination="true"
            :headers="sqlStatementClinicResultsHeaders"
            :hide-default-footer="true"
            :isLoading="sqlStatementClinicResultsLoading"
            :srvSideActPage="actPage"
            :srvSideActSize="actSize"
            :srvSideTotalItems="totalItems"
            :srvSideTotalPages="totalPages"
            :srvSideSizeItems="getTablePagination.rowsPerPageWithNames"
            item-key="SqlStatementClinicResultID"
            loadingText="Loading SQL statement result..."
            tableClass="foreground"
            @pageChanged="handlePageChange"
            @sizeChanged="handleSizeChange"
          >
            <template v-if="readClinics" v-slot:item.goToClinic="{ item }">
              <v-btn color="foreground" small @click="goToClinicView(item.SqlStatementsClinic.ClinicID)"
                >{{ item.SqlStatementsClinic.Clinic.name }}
              </v-btn>
            </template>
            <template v-else v-slot:item.goToClinic="{ item }">
              {{ item.SqlStatementsClinic.Clinic.name }}
            </template>
            <template v-slot:item.goToStatement="{ item }">
              <v-btn color="foreground" small @click="goToStatementEditor(item.SqlStatementsClinic.SqlStatementID)"
                >{{ item.SqlStatementsClinic.SqlStatement.name }}
              </v-btn>
            </template>
            <template v-slot:item.SqlStatementsClinic.linkedAt="{ item }">
              {{ item.SqlStatementsClinic.linkedAt.replace('Z', '').replace('T', ' ') }}
            </template>
            <template v-slot:item.createdAt="{ item }">
              {{ item.createdAt.replace('Z', '').replace('T', ' ') }}
            </template>
            <template v-slot:item.result="{ item }">
              <mex-btn icon="mdi-table-eye" iconOnly @click="fillResultTable(item)" />
            </template>
            <template v-slot:item.resultLength="{ item }">
              {{ getResultLength(item) }}
            </template>
          </mex-data-table>
        </mex-sheet>
      </v-col>
    </v-row>
    <mex-dialog
      :dialogTitle="`${resultTable.statementName} of ${resultTable.clinicName}`"
      :scrollbarLess="false"
      :showDialog="showResultTable"
      width="100%"
    >
      <template v-slot:dialog-content>
        <v-row justify="center">
          <v-col cols="12">
            <mex-data-table
              :data="resultTable.data"
              :headers="resultTable.headers"
              :footer-props="{
                'items-per-page-options': getTablePagination.rowsPerPage,
              }"
              :items-per-page="getTablePagination.defaultRowsPerPage"
            ></mex-data-table>
          </v-col>
        </v-row>
      </template>
      <template slot="dialog-actions">
        <v-row justify="center">
          <v-col cols="auto">
            <mex-btn content="Close" icon="mdi-close" @click="clearResultTable" />
          </v-col>
          <v-col cols="auto">
            <mex-btn content=".json" icon="mdi-code-json" width="100%" @click="downloadSqlStatementResults('json')" />
          </v-col>
          <v-col cols="auto">
            <mex-btn content=".csv" icon="mdi-file-excel" width="100%" @click="downloadSqlStatementResults( 'csv')" />
          </v-col>
        </v-row>
        <v-overlay  v-if="isDownloading" >
          <mex-sperm-spinner :spinnerText="isDownloadingText" />
        </v-overlay>
      </template>
    </mex-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import SqlStatementClinicResultsService from '../../services/sqlStatementClinicResults.service';
import requiredPermissions from '../../requiredPermissions';
import tablePagination from '../../config/tablePagination.config';
import {downloadFile} from '../../functions/downloadFiles'
import SqlStatementsService from "../../services/sqlStatements.service";

export default {
  name: 'SqlStatementResults',
  computed: {
    ...mapGetters('sysAuth', ['getUserPermissions']),
    readClinics() {
      return this.getUserPermissions.includes('read_Customer Administration_Clinic Administration');
    },
    getTablePagination() {
      return tablePagination;
    },
    getSpinnerText() {
      return this.isDownloadingText
    }
  },
  data() {
    return {
      // visualization
      sqlStatementClinicResultsLoading: false,
      showResultTable: false,
      isDownloading: false,
      isDownloadingText: '',
      // sql statement clinic results table
      resultTable: {
        statementName: '',
        clinicName: '',
        clinicID: '',
        data: [],
        headers: [],
      },
      sqlStatementClinicResultsData: [],
      sqlStatementClinicResultsHeaders: [
        {
          text: 'Clinic name',
          align: 'start',
          sortable: false,
          value: 'goToClinic',
        },
        {
          text: 'Statement name',
          align: 'start',
          sortable: true,
          value: 'goToStatement',
        },
        {
          text: 'Statement description',
          align: 'start',
          sortable: false,
          value: 'SqlStatementsClinic.SqlStatement.description',
        },
        {
          text: 'Linked At',
          align: 'start',
          sortable: true,
          value: 'SqlStatementsClinic.linkedAt',
        },
        {
          text: 'Result Received At',
          align: 'start',
          sortable: true,
          value: 'createdAt',
        },
        {
          text: 'Result',
          align: 'start',
          sortable: true,
          value: 'result',
        },
        {
          text: 'Result length',
          align: 'start',
          sortable: true,
          value: 'resultLength',
        },
      ],
      actPage: 1,
      actSize: tablePagination.defaultRowsPerPage,
      totalItems: 0,
      totalPages: 0,
      hasPermission: false,
    };
  },
  mounted() {
    this.fetchSqlStatementClinicResults();
  },
  methods: {
    fillResultTable(item) {
      this.resultTable.statementName = item.SqlStatementsClinic.SqlStatement.name;
      this.resultTable.clinicName = item.SqlStatementsClinic.Clinic.name;
      this.resultTable.clinicID = item.SqlStatementsClinic.ClinicID;
      // Only fill the resultTable if the result contains actual data
      if(JSON.parse(item.result).length > 0) {
        this.resultTable.data = JSON.parse(item.result);
        this.resultTable.headers = Object.keys(JSON.parse(item.result)[0]).map(function addHeaders(x) {
          return {
            text: x,
            align: 'start',
            sortable: true,
            value: x,
          };
        });
      }
      this.showResultTable = true;
    },
    getResultLength(item) {
      return JSON.parse(item.result).length;
    },
    clearResultTable() {
      this.showResultTable = false;
      this.resultTable.statementName = '';
      this.resultTable.clinicName = '';
      this.resultTable.data = [];
      this.resultTable.headers = [];
    },
    fetchSqlStatementClinicResults() {
      this.sqlStatementClinicResultsLoading = true;
      SqlStatementClinicResultsService.getSqlStatementClinicResults(this.actPage, this.actSize)
        .then((response) => {
          this.sqlStatementClinicResultsData = response.data.data;
          this.totalItems = response.data.totalItems;
          this.totalPages = response.data.totalPages;
          this.sqlStatementClinicResultsLoading = false;
        })
        .catch((err) => {
          this.$toast.error(err.response.data);
          this.sqlStatementClinicResultsLoading = false;
        });
    },
    handlePageChange(value) {
      this.actPage = value;
      this.fetchSqlStatementClinicResults();
    },
    handleSizeChange(value) {
      this.actPage = 1;
      this.actSize = value;
      this.fetchSqlStatementClinicResults();
    },
    goToClinicView(id) {
      this.$router.push({ name: 'ClinicView', params: { id } });
    },
    goToStatementEditor(id) {
      this.$router.push({ name: 'SqlStatementEditor', params: { id } });
    },

    downloadSqlStatementResults(fileType) {
      this.isDownloading = true;
      this.isDownloadingText = 'Saving the data';
      SqlStatementsService.getSqlStatementResults(this.resultTable.statementName, this.resultTable.clinicID, fileType, 1)
        .then((sqlStatementResponse) => {
          const filename = `${this.resultTable.statementName}_${this.resultTable.clinicName}`;
          const content = [sqlStatementResponse.data.result]
          downloadFile(content, filename, fileType).then(() => {
              this.isDownloading = false
            }
          ).catch((err) => {
            this.isDownloading = false;
            this.$toast.error(err.response.data);
            this.sqlStatementClinicResultsLoading = false;
          });
        }).catch((err) => {
        this.isDownloading = false;
        this.$toast.error(err.response.data);
        this.sqlStatementClinicResultsLoading = false;
      });
    },
  },
  created() {
    this.$userPermissions.fetchCurrentUserPermissions(requiredPermissions.sqlBroadcaster, this.$store)
      .then((hasPermission) => {
        if (hasPermission) {
          this.hasPermission = true;
        } else {
          this.$router.push({ name: "NotFound" });
        }
      })
      .catch(() => {
        this.$router.push({ name: "NotFound" });
      })
  },
};
</script>

<style scoped></style>
